<template>
    <div class="navhead flexab">
        <img :src="utils.loadImg('logo2.png')" alt=""  />
        <div class="rig flex flexa">
            <el-autocomplete
            v-if="current=='/service'"
            class="inline-input"
            v-model="state1"
            size="small"
            @keyup.enter="onSubmit" 
            :trigger-on-focus="false"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            @select="handleSelect"
            >
                <template #prepend>
                    <el-cascader
                    v-model="search1"
                    size="small"
                    style="width: 140px;font-size:12px"
                    :options="options"
                    @change="handleChange"
                    ></el-cascader>
                </template>
                <template #suffix>
                    <div @click="onSubmit" class="flex flexa padr10 point" style="height:100%" >
                        <el-icon class="el-icon-search"></el-icon>
                    </div>
                </template>
            </el-autocomplete>

            <el-tooltip placement="bottom">
                <template #content>
                    <div class="tc colf font10 padd10">
                        <img :src="utils.loadImg('ma2.png')" alt="" width="114" height="114" />
                        <p class="padt10">微信扫一扫</p>
                        <p class="padt4">及时查看工单进度</p>
                    </div>
                </template>
                <img :src="utils.loadImg('ma.png')" alt="" width="25" height="25" />
            </el-tooltip>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="navmsg" @click="$router.push('/news')">
                <el-badge :value="unread" :max="99" :hidden="unread==0? true: false" class="item">
                    <img :src="utils.loadImg('linf.png')" alt="" width="22"  />
                </el-badge>
            </div>

            <el-tooltip placement="bottom">
                <template #content>
                    <ul class="headul1">
                        <li  @click="$router.push('/account')">
                            <img :src=" utils.loadImg('user.png') " alt="" width="14" /> &nbsp;&nbsp;
                            账号中心
                        </li>
                        <li @click="tuichu">
                            <img :src="utils.loadImg('guan.png')" alt="" width="14" /> &nbsp;&nbsp;
                            退出登录
                        </li>
                    </ul>
                </template>
                <div class="flex flexa colf font12 point padl20">
                    <img class="borahalf" :src="userInfo.headPic" alt="" width="24"/> &nbsp;&nbsp;
                    {{userInfo.username}}
                    <i class="el-icon-caret-bottom el-icon--right colf"></i>
                </div>
            </el-tooltip>
        </div>
    </div>
    <div class="side navbar">
        <el-menu
        :collapse="isCollapse"
        class="el-menu-vertical-demo"
        @select="handleClick"
        :default-active="current"
        background-color="#25272B"
        text-color="#fff"
        :router="true"
        active-text-color="#fff">
            <el-menu-item :index="item.path " v-for="(item,index) in barList" :route="item.path" :key="index" >
                <img v-if="current== item.path " :src="utils.loadImg(item.src1)" alt="" width="16" height="16" /> 
                <img v-else :src="utils.loadImg(item.src2)" alt="" width="16" height="16" /> 
                &nbsp;
                <template #title>
                    {{item.tit}}
                    <img v-if="item.tit=='在线支持'" :src="utils.loadImg(item.src3)" alt="" width="10" height="10" class="marl20" />
                </template>
            </el-menu-item>
        </el-menu>
        <div  @click="isCollapse= !isCollapse" class="flex flexa open">
            <img v-if="isCollapse" :src="utils.loadImg('cols2.png')" alt="" width="16" height="16" />
            <img v-if="!isCollapse" :src="utils.loadImg('cols1.png')" alt="" width="16" height="16" /> &nbsp;
        </div>
    </div>
    <div v-if="myroute.meta.titL" :class="[ 'navmb',  {'navpl': isCollapse } ]">
        <div class="flex flexa" v-for="(item,index) in myroute.meta.titL" :key="index">
            <span v-if="index>0">&nbsp; >&nbsp; </span>
            <span :class=" index+ 1 == myroute.meta.titL.length&&index!=0 ? 'colzhuti': ''" >{{item}}</span>
        </div>
    </div>
    <div :class="[ 'navmin',  {'navpl': isCollapse } ]">
        <p v-if="myroute.meta.titL" class="h50"></p>
        <router-view></router-view>
    </div>

    <el-popover
    placement="left"
    :width="300"
    trigger="click"
    >
        <template #reference>
            <div class="btntip btnzhuti font14 ">
                <img :src="utils.loadImg('erji.png')" alt="" width="20" height="20" /> &nbsp;
                支持
            </div>
        </template>
        <ul class="nowrap padt14 padb14 padl20">
            <li class="flex flexa">
                <img :src="utils.loadImg('f1.png')" alt=""  />
                <div class="padl10">
                    <p class="col24 font14 ">电话咨询</p>
                    <p class="col4c font12 fontw4">4000123005</p>
                </div>
            </li>
            <li class="flex flexa padt24">
                <img :src="utils.loadImg('f2.png')" alt=""  />
                <div class="padl10">
                    <p class="col24 font14 ">在线客服</p>
                    <p class="col4c font12 fontw4">专业工程师，为您快速解决数据库问题</p>
                </div>
            </li>
            <li class="flex flexa padt24">
                <img :src="utils.loadImg('f3.png')" alt=""  />
                <div class="padl10">
                    <p class="col24 font14 ">提交工单</p>
                    <p class="col4c font12 fontw4">产品问题、技术问题、常见问题快速响应</p>
                </div>
            </li>
            <li class="flex flexa padt24">
                <img :src="utils.loadImg('f4.png')" alt=""  />
                <div class="padl10">
                    <p class="col24 font14 ">聆听反馈</p>
                    <p class="col4c font12 fontw4">您的建议我们会及时处理/反馈，非常感谢</p>
                </div>
            </li>
        </ul>
    </el-popover>

</template>
<script>
import {  recommended } from "@/utils/api1.js"
import {  orderStatistical, workOrderListTechnology, workOrderListNormal } from "@/utils/api2.js"

export default {
    inject:['routerRefresh'],
    data(){
        return{
            search1: [ 1 ],
            barList:[
                { tit: '服务请求', src2:'icon1.png', src1:'icon1-a.png', path:'/service' },
                { tit: '知识库文档', src2:'icon2.png', src1:'icon2-a.png', path:'/knowledge' },
                // { tit: '在线支持', src2:'icon3.png', src1:'icon3-a.png', src3:'up2.png', path:'#' },
                // { tit: '补丁/更新 程序', src2:'icon4.png', src1:'icon4-a.png', path:'#' },
                // { tit: '认证', src2:'icon5.png', src1:'icon5-a.png', path:'#' },
                { tit: '账号中心', src2:'icon6.png', src1:'icon6-a.png', path:'/account' },
            ],
            current: '/service',
            myroute: this.$route,
            isCollapse: false,
            state1:'',
            screenWidth: document.body.clientWidth, 
            userInfo: this.$store.state.userinfo ,
            unread: 0,
            options: [ 
                { value: 1 , label:'知识库文档'  },
                { 
                    value: 2 , label:'工单',
                    children:[
                        { 
                            value: '2-1' , label:'技术性工单', 
                            children:[
                                { value: 'problemProfile' , label:'问题概要', },
                                { value: 'outTradeNo' , label:'技术请求编号', },
                            ]
                        },
                        { 
                            value: '2-2' , label:'非技术性工单',
                            children:[
                                { value: 'problemProfile' , label:'问题概要', },
                                { value: 'outTradeNo' , label:'技术请求编号', },
                            ]
                        },
                    ],
                    
                },
            ]
        }
    },
    
    watch:{
        '$route' (val, old) {
            this.myroute = val;
            console.log(val, old)
            if(val.meta.tab&& val.meta.tab != this.current ){
                this.current = val.meta.tab;
            };
        },
        screenWidth (val) {
            this.screenWidth = val;
            // console.log(val, );
            if(val<1100){
                this.isCollapse = true
            }else{
                this.isCollapse = false

            }
        }
    },
    mounted(){
        this.getData();
        console.log(this.userInfo)
        this.myroute = this.$route;
        this.current = this.$route.meta.tab;
        // console.log('dj')
        const that = this; 
        window.onresize = () => {
            return (() => {
                // window.screenWidth = document.body.clientWidth
                // that.screenWidth = window.screenWidth
                that.screenWidth = document.body.clientWidth
            })()
        };
    },
    methods:{
        onSubmit(val){
            console.log(val,'回车')
            // console.log(this.state1);
            if(this.search1[0] == 1 ){
                this.$router.push({
                    path: '/search/sch/null',
                    query: {
                    value: this.state1
                    }
                })
            }
        },

        handleChange(val){
            console.log(val)

        },
        tuichu(){
			localStorage.setItem('hltoken', '');
            this.$store.state.token = '';
            this.$router.push('/login');
        },
        handleClick(e) {
            // console.log('click ', e, this.current);
            this.current = e;
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        handleSelect(val){
            console.log(val);
            let sechtype = this.search1;
            if(sechtype[0]==1){
                this.$router.push({
                    path: '/search/sch/l',
                    query: val
                })
                // if(val.type=='4'){
                //     // 知识文档
                //     this.$router.push('/knowledgeInfo/'+val.id)
                // }else{
                //     this.$router.push({
                //         path: '/search/sch/l',
                //         query: val
                //     })
                // }
            }else{
                // console.log(this.$route);
                // if(this.$route.name=='info'){
                //     this.$router.push('/info/'+val.id);
                // }
                this.$router.push('/info/'+val.id)
            }
        },
        querySearch( txt ,cb ){
            console.log(txt, this.search1);
            let sechtype = this.search1;
            if(sechtype[0]==1){
                recommended({searchContent:txt, isShow: 1}).then(res=>{
                    console.log(res);
                    if(res){
                        let result = res.map((terminal) => {
                            return {
                                value: terminal.categoryTitle,
                                id: terminal.id,
                                type: terminal.type
                            };
                        });
                        cb(result)
                    }else{
                        let arr = [];
                        cb(arr)
                    }
                })
            }else{
                let obj = {
                    pageNo: 1,
                    pageSize: 30,
                    customerCSINumber:[],
                    myAccept: false,
                    myCollection: false,
                    myOngoing: false,
                    outTradeNo: '',
                    problemProfile: '',
                }
                obj[sechtype[2]] = txt;
                console.log(obj)
                if(sechtype[1]=='2-1'){
                    workOrderListTechnology(obj).then(res=>{
                        console.log(res);
                        if(res){
                            let result = res.voList.map((terminal) => {
                                return {
                                    value: terminal.problemProfile,
                                    id: terminal.id,
                                };
                            });
                            cb(result)
                        }else{
                            let arr = [];
                            cb(arr)
                        }
                    })
                }else{
                    workOrderListNormal(obj).then(res=>{
                        console.log(res);
                        if(res){
                            let result = res.voList.map((terminal) => {
                                return {
                                    value: terminal.problemProfile,
                                    id: terminal.id,
                                };
                            });
                            cb(result)
                        }else{
                            let arr = [];
                            cb(arr)
                        }
                    })
                }
            }
        },

        getData(){
            orderStatistical().then(res=>{
                console.log(res);
                if(res){
                    this.unread = res.accountUnreadMessageCount;
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.headul1{
    li{
        display: flex;
        height: 36px;
        color: #fff;
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 144px;
    }
    li:nth-child(1){
        border-bottom: 0.5px solid rgb(99, 98, 98);
    }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
  }
.open{
    height: 56px;
    padding: 0 20px;
    cursor: pointer;
    position: absolute;
    width: 100%;
    bottom: 0;
    box-sizing: border-box;
}
.open:hover{
    background-color: rgb(30,31,34);
}
.navmin{
    background: #F6F6F6;
    min-height: 100vh;
    box-sizing: border-box;
    padding-top: 50px;
    padding-right: 20px;
    padding-left: 220px;
    padding-bottom: 20px;
    transition: padding-left 0.5s;
    overflow: hidden;
}
.navpl {
    padding-left: 83px!important;
}
.navmb{
    position: fixed;
    left: 0px;
    padding-left: 220px;
    top: 50px;
    z-index: 8;
    background: #fff;
    font-size: 16px;
    height: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    color: #151822;
    font-weight: 600;
    transition: padding-left 0.5s;
}
.navhead{
    background: #34383F;
    height: 50px;
    padding: 0 25px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 22;
    box-sizing: border-box;
}
.side{
    padding-top: 50px;
    padding-bottom: 60px;
    background: #25272B;
    position: fixed;
    box-sizing: border-box;
    height: 100%;
    left: 0;
    z-index: 10;
    top: 0;
    border-right: solid 1px var(--el-menu-border-color);
}

.btntip{
    color: #fff;
    writing-mode:vertical-lr;
    display: flex;
    align-items: center;
    width: 42px;
    padding: 15px 0;
    box-shadow: 0px 2px 8px 0px #FFC49A;
    position: fixed;
    right: 10px;
    bottom: 26%;
    z-index: 100;
}
.navmsg{
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navmsg:hover{
    background: #4C4F55;
}
.inline-input{
    width: 232px;
    margin-right: 30px;
}
</style>